import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Product, ContentNode as ContentNodeType } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, LinkEntityButton, useEntity, useLang, useLink } from '@shapeable/ui';
import { classNames, entityColorInfo } from '@shapeable/utils';
import { ProductImage } from './product-image';
import { get } from 'lodash'
const cls = classNames('product-card');

// -------- Types -------->

export type ProductCardProps = Classable & HasChildren & { 
  entity?: Product;
};

export const ProductCardDefaultProps: Omit<ProductCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type BodyProps = {
  _color: string;
  _backgroundColor: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    height: 100%;
    ${({ _color, _backgroundColor }: BodyProps ) => css`
      color: ${_color};
      background-color: ${_backgroundColor};
    `}
  `,
});

const LayoutStyles = breakpoints({
  base: css`

  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${theme.UNIT(4)};
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(10)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: ${20/15}em;
    font-weight: 500;
    margin: 0;
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    
  `,
});

const ContentWrapperStyles = breakpoints({
  base: css`
    min-height: 50px;
  `,
});



const ContentStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(14)};
    font-weight: 200;
  `,
});
  
const LinkButtonStyles = breakpoints({
  base: css`
   
  `,
});

const ImageStyles = breakpoints({
  base: css`
    transition: filter 0.2s;
    &:hover {
      filter: brightness(1.2);
    }
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<BodyProps>`${ContainerStyles}`,
    Layout: styled.div`${LayoutStyles}`,
      Image: styled(ProductImage)`${ImageStyles}`,
      Body: styled.div`${BodyStyles}`,
      Title: styled.h2`${TitleStyles}`,
      Subtitle: styled.div`${SubtitleStyles}`,
      ContentWrapper: styled.div`${ContentWrapperStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,

    LinkButton: styled(LinkEntityButton)`${LinkButtonStyles}`,

};

export const ProductCard: Shapeable.FC<ProductCardProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { description, name, banner, links = [] } = entity

  const { Link } = useLink();

  const { backgroundColor, color } = entityColorInfo(entity)

  const linkColor = get(links, '[0].color.name', 'light');
  const firstLink = get(entity, `links[0].page.path`, get(entity, `links[0].url`));

  const linkButtonColor = linkColor || color;

  const t = useLang()
  const image = <My.Image entity={entity}/>;

  return (
   <My.Container _backgroundColor={backgroundColor} _color={color} className={cls.name(className)}>
    <My.Layout>
      {
        firstLink ? 
        <Link href={firstLink}>{image}</Link> :
        image
      }
      
      <My.Body>
        {
          name && 
          <My.Title>{name}</My.Title>
        }
        {
          description && 
          <My.ContentWrapper>
          <My.Content entity={description as ContentNodeType}/>
          </My.ContentWrapper>
        }
        {
          links.map(link => <My.LinkButton key={link.id} color={linkButtonColor} hoverColor='link-hover' entity={link} variant='slim'>{t('Explore Product')}</My.LinkButton>)
        }
      </My.Body>
    </My.Layout>
   {children}
   </My.Container>
  )
};

ProductCard.defaultProps = ProductCardDefaultProps;
ProductCard.cls = cls;