import { 
  COLOR_WARM_GREY,
  SliceBlurbImage,
  SliceContentBlockLinks,
  SliceContentImage,
  SliceImageBlocks,
  SliceIntro,
  SliceIntroProps,
  SlicePersonBioGrid,
  SlicePersonBioGridProps,
  createSliceLayout,
} from '@shapeable/ui';
import { ProductGrid } from '../entities/product-grid';
import { SliceBannerTout, SliceBannerToutProps } from '../entities/slice-banner-tout';
import { SliceProductBenefits } from '../entities/slice-product-benefit';
import { SliceProductIndustries } from '../entities/slice-product-industries';
import { YELLOW } from '../../theme';

// Augment standard site layouts here by exporting additional or replacement layouts.
// (simply use the same key to replace any existing slice layouts).

export const SliceLayoutProductGrid = createSliceLayout(
  ProductGrid, {
    verticalPadding: 'small',
    boundary: 'content-bleed',
    props: {
      desktopAutoColumns: 2,
      tabletAutoColumns: 2,
    }
  },
);

export const SliceLayoutPeople = createSliceLayout<SlicePersonBioGridProps>(
  SlicePersonBioGrid, {
    verticalPadding: 'small',
    props: {
      transform: '',
    }
  }
);

export const SliceLayoutBannerTout = createSliceLayout<SliceBannerToutProps>(
  SliceBannerTout, {
    verticalPadding: 'large',
    props: {
      
    }
  }
);

export const SliceLayoutBenefits = createSliceLayout(
  SliceProductBenefits, {
    verticalPadding: 'large',
  }
);

export const SliceLayoutProductIndustries = createSliceLayout(
  SliceProductIndustries  , {
    verticalPadding: 'small',
    boundary: 'content',
    defaultBackgroundColor: COLOR_WARM_GREY,
  }
);

export const SliceLayoutKeyPillars = createSliceLayout(
  SliceImageBlocks, {
    verticalPadding: 'small',
  }
);

export const SliceLayoutContentBlockLinks = createSliceLayout(
  SliceContentBlockLinks, {
    verticalPadding: 'medium',
    props: {
      hoverColor: YELLOW,
    }
  }
);

export const SliceLayoutIntro = createSliceLayout(
  SliceIntro, {
    verticalPadding: 'small',
  }
);

export const SliceLayoutContentImage = createSliceLayout(
  SliceContentImage, {
    verticalPadding: 'none',
  }
);