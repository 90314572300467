import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Link } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
const cls = classNames('rounded-button');

// -------- Types -------->

export type ButtonRoundedProps = Classable & HasChildren & {
}

export const ButtonRoundedDefaultProps: ButtonRoundedProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: ${theme.FONT('sans')};
    padding: ${theme.UNIT(2)} ${theme.UNIT(6)};
    border: 1px solid ${theme.COLOR('light')};
    border-radius: 29px;
    width: 160px;
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(8)};
    width: 210px;
  `,
  desktop: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(10)};
    width: 240px;
  `,
});

const LabelStyles = breakpoints({
  base: css`
    
    font-family: Roboto Mono;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  `,
  tablet: css`
    font-size: 12px;
  `,
  desktop: css`
    font-size: 16px;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.span<ContainerProps>`${ContainerStyles}`,
    Label: styled.span`${LabelStyles}`,

};

export const ButtonRounded: Shapeable.FC<ButtonRoundedProps> = (props) => {
  const { className } = props;

  

  return (
    <My.Container className={cls.name(className)}>
      <My.Label>Join the movement</My.Label>
    </My.Container>
  )
};

ButtonRounded.cls = cls;
ButtonRounded.defaultProps = ButtonRoundedDefaultProps;